// components/playerStatistics/TempoFilter.js
import React from 'react';
import { Form } from 'react-bootstrap';

function TempoFilter({ setTempo }) {
  return (
    <Form.Group>
      <Form.Label>Filter by Tempo</Form.Label>
      <Form.Control as="select" onChange={(e) => setTempo(e.target.value)}>
        <option value="">Alle Partijen</option>
        <option value="3">Regulier</option>
        <option value="2">Rapid</option>
        <option value="1">Snelschaken</option>
      </Form.Control>
    </Form.Group>
  );
}

export default TempoFilter;
