import {React, useContext, useEffect} from 'react';
import AppContext from '../AppContext';
import axios from 'axios';
import { useQuery } from 'react-query';

function SeasonSelector() {
  const { setSelectedSeasonId } = useContext(AppContext);
  const { isLoading, error, data } = useQuery('seasons', () =>
    axios.get('http://localhost/seasons').then((res) => res.data)
  );

  useEffect(() => {
    if (!isLoading && data.length > 0) {
      const lastSeason = data.sort((a, b) => b.id - a.id)[0];
      setSelectedSeasonId(lastSeason.id);
    }
  }, [isLoading, data, setSelectedSeasonId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  
  return (
    <select className="form-select" onChange={(e) => setSelectedSeasonId(parseInt(e.target.value))}>
      {data.sort((a, b) => (a.naam > b.naam) ? -1 : 1).map((season) => (
        <option key={season.id} value={season.id}>{season.naam}</option>
      ))}
    </select>

  );
}

export default SeasonSelector;
