import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import AppContext from '../../AppContext';

function RoundGames({ players, roundId }) {
  const { selectedCompetitionId, selectedRound, selectedPlayer, setSelectedPlayer } = useContext(AppContext);
  const [games, setGames] = React.useState([]);
  useEffect(() => {
    const fetchGames = async () => {
      if (selectedCompetitionId && roundId) {
        const response = await axios.get(`http://localhost/competitions/${selectedCompetitionId}/rounds/${roundId}/matches`);
        setGames(response.data);
      }
    };
    fetchGames();
  }, [selectedCompetitionId, roundId]);

  const playerLookup = players.reduce((acc, player) => {
    acc[player.id] = `${player.voornaam} ${player.achternaam}`;
    return acc;
  }, {});


  return (
    <>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th>Witspeler</th>
            <th></th>
            <th>Zwartspeler</th>
            <th>Uitslag</th>
          </tr>
        </thead>
        <tbody>
          {games.map((game) => (
            <tr key={game.id}>
              <td onClick={() => setSelectedPlayer(game.speler_wit)}>
                {playerLookup[game.speler_wit]}
              </td>
              <td>-</td>
              <td onClick={() => setSelectedPlayer(game.speler_zwart)}>
                {playerLookup[game.speler_zwart]}
              </td>
              <td>
                {game.uitslag === 1 && '1-0'}
                {game.uitslag === 2 && '\u00BD-\u00BD'}
                {game.uitslag === 3 && '0-1'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default RoundGames;
