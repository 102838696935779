import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import AppContext from '../../AppContext';
import { Link } from 'react-router-dom';

function CompetitionPlayerGames({ player, players }) {
  const { selectedCompetitionId, selectedPlayer, setSelectedPlayer} = useContext(AppContext);
  const [games, setGames] = React.useState([]);

  const playerLookup = players.reduce((acc, player) => {
    acc[player.id] = player;
    return acc;
  }, {});

  useEffect(() => {
    const fetchGames = async () => {
      const response = await axios.get(
        `http://localhost/competitions/${selectedCompetitionId}/players/${player}/matches`
      );
      setGames(response.data);
    };
    fetchGames();
  }, [selectedCompetitionId, player]);


  const getGameResultClass = (result, gamePlayer, gamePlayerWhite) => {
    console.log(result);
    console.log(gamePlayer);
    console.log(gamePlayerWhite);
    if(result == 1)
        if(gamePlayer == gamePlayerWhite)
            return 'success';
        else
            return 'danger';
    else if (result == 3)
        if(gamePlayer == gamePlayerWhite)
            return 'danger';
        else
            return 'success';
  };

  if (!player || player == '' || !players) {
    return <div>Loading...</div>;
  }

  return (
    <div className="competition-player-games">
      <h2>{`${playerLookup[player].voornaam} ${playerLookup[player].achternaam}`}</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Ronde</th>
            <th>Wit</th>
            <th></th>
            <th>Zwart</th>
            <th>Uitslag</th>
          </tr>
        </thead>
        <tbody>
          {games.map((game) => (
            <tr key={game.id} >
              <td>{game.ronde} - {game.datum}</td>
              <td><Link to={`../speler/${game.speler_wit}`}>{playerLookup[game.speler_wit].voornaam} {playerLookup[game.speler_wit].achternaam} ({game.rating_wit})</Link></td>
              <td>-</td>
              <td><Link to={`../speler/${game.speler_zwart}`}>{playerLookup[game.speler_zwart].voornaam} {playerLookup[game.speler_zwart].achternaam} ({game.rating_zwart})</Link></td>
              <td  className={getGameResultClass(game.uitslag, player, game.speler_wit)}>
                {game.uitslag === 1 && '1-0'}
                {game.uitslag === 2 && '\u00BD-\u00BD'}
                {game.uitslag === 3 && '0-1'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CompetitionPlayerGames;
