import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import AppContext from './AppContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { HashRouter, Routes, Route } from 'react-router-dom';

import { Navigate } from 'react-router-dom';
import axios from 'axios';

import Navigation from './components/navigation';
import Competition from './components/competition/competition';
import PlayerStatistics from './components/playerStatistics/playerStatistics';

import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();

const fetchLatestSeasonAndCompetition = async () => {
  const seasonsResponse = await axios.get('http://localhost/seasons');
  const latestSeason = seasonsResponse.data.sort((a, b) => b.id - a.id)[0];

  const competitionsResponse = await axios.get(`http://localhost/seasons/${latestSeason.id}/competitions`);
  const latestCompetition = competitionsResponse.data[0];

  return {
    seasonId: latestSeason.id,
    competitionId: latestCompetition.id,
  };
};

function RedirectToLatestCompetition() {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const { seasonId, competitionId } = await fetchLatestSeasonAndCompetition();
      navigate(`/intern/seizoen/${seasonId}/competitie/${competitionId}/ronde//stand`);
    };
    fetchData();
  }, [navigate]);

  return null;
}

function App() {
  const location = useLocation();

  const [urlParams, setUrlParams] = useState([]);
  const [selectedSeasonId, setSelectedSeasonId] = useState(null);
  const [selectedCompetitionId, setSelectedCompetitionId] = useState(null);
  const [selectedRound, setSelectedRound] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState([]);

  useEffect(() => {
    setUrlParams(location.pathname.split('/'));
  }, [location.pathname]);

  return (
    <AppContext.Provider value={{ selectedSeasonId, setSelectedSeasonId, selectedCompetitionId, setSelectedCompetitionId, selectedRound, setSelectedRound, selectedTab, setSelectedTab, selectedPlayer, setSelectedPlayer }}>
      <QueryClientProvider client={queryClient}>
        <div className="App container-fluid">
          <Navigation />
          <Routes>
            <Route path="intern" element={<RedirectToLatestCompetition />} />
            <Route path="intern/seizoen/:seasonId/competitie/:competitionId/*" element={<Competition competitionId={urlParams[5]} roundId={(urlParams[6] === "ronde" ? urlParams[7] : null)} />} />
            <Route path="/spelers" element={<PlayerStatistics />} />
            {/* add other routes here */}
          </Routes>
        </div>
      </QueryClientProvider>
    </AppContext.Provider>
  );
}

export default App;
