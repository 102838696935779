import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import AppContext from '../../AppContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';

function CompetitionRounds({ rounds, roundId = null }) {
  const { setSelectedRound, selectedRound } = useContext(AppContext);
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    const setDefaultRound = async () => {
      if (roundId > 0 && roundId != "null")
        setSelectedRound(rounds[roundId]);
      else
      {
        if(rounds.length > 0)
        {

          setSelectedRound(rounds[rounds.length - 1]);
          roundId = rounds.length;

          let pathParts = location.pathname.split('/'); 
          pathParts[7] = roundId;
          if(!pathParts[8])
            pathParts[8] = "stand";

          if(pathParts[6] == "ronde")
            navigate(pathParts.join('/'));  
        }
      }
    };
    setDefaultRound();
  }, [rounds, roundId, selectedRound]);

  if (!rounds) {
    return null;
  }
  return (
    <div>
      <h2>Rondes</h2>
      {rounds ? (
        <ul className='rounds'>
          {rounds.map((round) => (
            <li key={round.id}>
              <Link 
                onClick={() => setSelectedRound(round)} 
                className={(roundId && round.round == rounds[roundId-1].round) ? 'selected' : ''} 
                to={`./ronde/${round.round}/stand`}>
                {round.round} - {round.date}
              </Link>
            </li>
          ))}
        </ul>) : (<p>Loading rounds</p>)}
    </div>
  );
}

export default CompetitionRounds;
