import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import { NavLink } from 'react-router-dom';

function CompetitionStandings({standings, players, competitionOptions}) {

  const { setSelectedPlayer } = useContext(AppContext);

  if (!competitionOptions || !players || !standings || competitionOptions.length == 0 || players.length  == 0 || standings.length  == 0) {
    return <div>No data available</div>;
  }

  const displayFields = competitionOptions.DisplayFields.value.split(',');

  const headerRow = displayFields.map((field) => (
    <th key={field}>{field}</th>
  ));

  const sortedStandings = standings.sort((a, b) => {
      const field = "Ranking";
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
    return 0;
  });

  const rows = sortedStandings.map((standing) => {
    const playerName = displayFields.includes('Name')
      ? players.find((player) => player.speler_id === standing.player_id)
          .voornaam +
        ' ' +
        players.find((player) => player.speler_id === standing.player_id)
          .achternaam
      : '';

    const cells = displayFields.map((field) => {
      if (field === 'Name') {
        return <td key={field} onClick={() => setSelectedPlayer(standing.player_id)}><NavLink to={`../speler/${standing.player_id}`}>{playerName}</NavLink></td>;
      } else {
        return <td key={field}>{standing[field]}</td>;
      }
    });

    return <tr key={standing.player_id}>{cells}</tr>;
  });

  return (
    <table className='table table-striped'>
      <thead>
        <tr>{headerRow}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}

export default CompetitionStandings;
