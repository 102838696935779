import React, { useState, useContext } from 'react';
import AppContext from '../AppContext';
import { Navbar, Nav, Form, Row, Col } from 'react-bootstrap';
import SeasonSelector from './seasonSelector';
import CompetitionSelector from './competitionSelector';

function Navigation() {
    const { selectedSeasonId } = useContext(AppContext);
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home">SV Nieuwerkerk</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="#/spelers">Spelers</Nav.Link>
                    <Nav.Link href="#/intern">Intern</Nav.Link>
                    <Nav.Link href="#/extern">Extern</Nav.Link>
                </Nav>
                <Form>
                    <Row className="align-items-center">
                        <Col xs="auto">
                            <SeasonSelector />
                        </Col>
                        {selectedSeasonId && (
                            <Col xs="auto">
                                <CompetitionSelector
                                    selectedSeasonId={selectedSeasonId}

                                />
                            </Col>
                        )}
                    </Row>
                </Form>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;
