// components/playerStatistics/PlayerSelector.js
import React from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';

function PlayerSelector({ players, setSelectedPlayer }) {
  return (
    <Form.Group>
      <Form.Label>Select Player</Form.Label>
      <Form.Control as="select" onChange={(e) => setSelectedPlayer(players.find((p) => p.id === parseInt(e.target.value)))}>
        <option value="">-- Selecteer een speler --</option>
        {players.map((player) => (
          <option key={player.id} value={player.id}>
            {player.voornaam} {player.tussenvoegsel} {player.achternaam}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}

export default PlayerSelector;
