// components/playerStatistics/OpponentsTable.js
import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';

function OpponentsTable({ playerId, tempo }) {
  const [opponents, setOpponents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post('https://svnieuwerkerk.nl/archief/asynch.php', {
        player: playerId,
        asynchAction: 4,
        Tempo: tempo,
      });
      setOpponents(response.data);
    };
    if (playerId) {
      fetchData();
    }
  }, [playerId, tempo]);

  return (
    <>
      <h3>Opponents</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Tegenstander</th>
            <th>Partijen</th>
            <th>Winst</th>
            <th>Remise</th>
            <th>Verlies</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {opponents.map((opponent) => (
            <tr key={opponent.TegenstanderId}>
              <td>{opponent.name}</td>
              <td>{parseInt(opponent.Winst) + parseInt(opponent.Remise) + parseInt(opponent.Verlies)}</td>
              <td>{opponent.Winst}</td>
              <td>{opponent.Remise}</td>
              <td>{opponent.Verlies}</td>
              <td>{(((parseInt(opponent.Winst) + parseInt(opponent.Remise) / 2) / (parseInt(opponent.Winst) + parseInt(opponent.Remise) + parseInt(opponent.Verlies))) * 100).toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default OpponentsTable;
