// components/playerStatistics/PlayerStatistics.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import PlayerSelector from './playerSelector.js';
import TempoFilter from './tempoFilter';
import PieCharts from './pieCharts';
import RatingChart from './ratingChart';
import OpponentsTable from './opponentsTable';

function PlayerStatistics() {
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [tempo, setTempo] = useState('');

  useEffect(() => {
    const fetchPlayers = async () => {
      const response = await axios.get('http://localhost/members');
      setPlayers(response.data);
    };
    fetchPlayers();
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h2>Speler Statistieken</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <PlayerSelector players={players} setSelectedPlayer={setSelectedPlayer} />
        </Col>
      </Row>
      {selectedPlayer && (
        <>
          <Row>
            <Col>
              <TempoFilter setTempo={setTempo} />
            </Col>
          </Row>
          <Row>
            <Col>
              <PieCharts playerId={selectedPlayer.id} tempo={tempo} />
            </Col>
          </Row>
          <Row>
            <Col>
              <RatingChart playerId={selectedPlayer.id} />
            </Col>
          </Row>
          <Row>
            <Col>
              <OpponentsTable playerId={selectedPlayer.id} tempo={tempo} />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default PlayerStatistics;
