// components/playerStatistics/PieCharts.js
import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';

function PieCharts({ playerId, tempo }) {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post('https://svnieuwerkerk.nl/archief/asynch.php', {
        player: playerId,
        asynchAction: 2,
        color: '',
        Tempo: tempo,
      });
      const { Winst, Remise, Verlies } = response.data;
      setData({
        labels: ['Winst', 'Remise', 'Verlies'],
        datasets: [
          {
            data: [Winst, Remise, Verlies],
            backgroundColor: ['green', 'gray', 'red'],
          },
        ],
      });
    };
    if (playerId) {
      fetchData();
    }
  }, [playerId, tempo]);

  return (
    <>
      <h3>Scores</h3>
      <Pie data={data} />
    </>
  );
}

export default PieCharts;
