// components/playerStatistics/RatingChart.js
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';

function RatingChart({ playerId }) {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post('http://localhost/archief/asynch.php', {
        player: playerId,
        asynchAction: 1,
      });
      const ratings = response.data;

      setData({
        labels: ratings.map((r) => r.datum),
        datasets: [
          {
            label: 'Rating',
            data: ratings.map((r) => r.rating),
            borderColor: 'blue',
            fill: false,
          },
        ],
      });
    };

    if (playerId) {
      fetchData();
    }
  }, [playerId]);

  return (
    <>
      <h3>Rating</h3>
      <Line data={data} />
    </>
  );
}

export default RatingChart;
