import {React, useContext, useEffect} from 'react';
import AppContext from '../AppContext';
import { useQuery } from 'react-query';
import axios from 'axios';
import { DropdownButton, Dropdown, Form } from 'react-bootstrap';

function CompetitionSelector({ selectedSeasonId }) {
    const { setSelectedCompetitionId } = useContext(AppContext);
    const { isLoading, error, data } = useQuery(
        ['competitions', selectedSeasonId],
        () => axios.get(`http://localhost/seasons/${selectedSeasonId}/competitions`).then(res => res.data)
    );

    useEffect(() => {
        if (!isLoading && data.length > 0) {
          setSelectedCompetitionId(data[0].id);
        }
      }, [data, isLoading, setSelectedCompetitionId]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <select className="form-select" onChange={(e) => setSelectedCompetitionId(parseInt(e.target.value))}>
            {data.map((competition) => (
                <option key={competition.id} value={competition.id}>{competition.naam}</option>
            ))}
        </select>

    );
}

export default CompetitionSelector;
