import { useQuery } from 'react-query';
import axios from 'axios';
import { React, useContext } from 'react';
import AppContext from '../../AppContext';
import { Table } from 'react-bootstrap';

function CompetitionDetails({competitionId}) {
    const { selectedSeasonId, selectedCompetitionId } = useContext(AppContext);

    const { isLoading, error, data } = useQuery(
        ['competitions', selectedSeasonId],
        () => axios.get(`http://localhost/seasons/${selectedSeasonId}/competitions`).then(res => res.data)
    );

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const competition = data.find(c => c.id == competitionId);

    if (!competition) return <div>Competition not found.</div>;

    return (
        <Table>
            <tbody>
                <tr>
                    <td>Naam</td>
                    <td>{competition.naam}</td>
                </tr>
                <tr>
                    <td>Tempo</td>
                    <td>{competition.speeltempo}</td>
                </tr>
                <tr>
                    <td>Plaats</td>
                    <td>{competition.plaats}</td>
                </tr>
            </tbody>
        </Table>
    );
}

export default CompetitionDetails;
