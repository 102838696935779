import { useQuery } from 'react-query';
import axios from 'axios';
import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import { Routes, Route, NavLink, useParams, useLocation} from 'react-router-dom';

import CompetitionDetails from './competitionDetails';
import CompetitionRounds from './competitionRounds';
import CompetitionStandings from './competitionStandings';
import RoundGames from './roundGames';
import CompetitionCrosstable from './competitionCrosstable';
import CompetitionPlayerGames from './competitionPlayerGames';

import '../../assets/styles/competition.css';

function Competition({competitionId, roundId = null}) {

    const [standings, setStandings] = React.useState([]);
    const [players, setPlayers] = React.useState([]);
    const [competitionOptions, setCompetitionOptions] = React.useState([]);
    const [rounds, setRounds] = React.useState([]);

    const location = useLocation();
    const path = location.pathname;
    
    React.useEffect(() => {
        const fetchStandings = async () => {
            if (competitionId && competitionId !== null && roundId !== null) {
                const response = await axios.get(`http://localhost/competitions/${competitionId}/standings/${roundId}`);
                setStandings(response.data);
            }
        };
        fetchStandings();
    }, [competitionId, roundId]);

    React.useEffect(() => {
        const fetchParticipants = async () => {
            if (competitionId && competitionId !== null) {
                const response = await axios.get(`http://localhost/competitions/${competitionId}/participants`);
                setPlayers(response.data);
            }
        };
        fetchParticipants();
    }, [competitionId]);

    React.useEffect(() => {
        const fetchCompetitionOptions = async () => {
            if (competitionId && competitionId !== null) {
                const response = await axios.get(`http://localhost/competitions/${competitionId}/options`);
                setCompetitionOptions(response.data);
                console.log("COMPETITION OPTIONS")
                console.log(response);
            }
        };
        fetchCompetitionOptions();
    }, [competitionId]);

    React.useEffect(() => {
        const fetchRounds = async () => {
            if(competitionId){
                const response = await axios.get(`http://localhost/competitions/${competitionId}/rounds`);
                setRounds(response.data);
            }
        };
        fetchRounds();
      }, [competitionId, roundId]);

    return (
        <div>
            {competitionId ? (
                <div className='row'>
                    <div className='col-sm-12'><CompetitionDetails competitionId={competitionId} /></div>
                    <div className='col-sm-2'><CompetitionRounds rounds={rounds} roundId={roundId}/></div>

                    { console.log({standings, competitionOptions, players})}
                    {standings.length > 0 && competitionOptions.DisplayFields && players.length > 0 ? (
                        <div className='col-sm-10'>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to={`./ronde/${roundId}/stand`}>Stand</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to={`./ronde/${roundId}/indeling`}>Indeling</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to={`./ronde/${roundId}/kruistabel`}>Kruistabel</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link disabled" activeClassName="active" to={`./speler/${path.substring(location.pathname.lastIndexOf("/") + 1)}`}>Speler</NavLink>
                                </li>
                            </ul>
                            <Routes>
                                <Route path="ronde/:roundId/stand" element={<CompetitionStandings standings={standings} players={players} competitionOptions={competitionOptions} />} />
                                <Route path="ronde/:roundId/indeling" element={<RoundGames players={players} roundId={roundId} />} />
                                <Route path="ronde/:roundId/kruistabel" element={<CompetitionCrosstable standings={standings} players={players} round={rounds[roundId]} />} />
                                <Route path="speler/:selectedPlayer" element={<CompetitionPlayerGames player={path.substring(location.pathname.lastIndexOf("/") + 1)} players={players} />} />
                            </Routes>
                        </div>
                    ) : (<div>No round selected</div>)}
                </div>
            ) : (
                <div>No competition selected</div>
            )}
        </div>
    );
}

export default Competition;
