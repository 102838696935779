import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';


function CompetitionCrosstable({ standings, players, round }) {
  if(!round)
    return;
  const sortedStandings = [...standings].sort((a, b) => b.score - a.score);

  const playerLookup = players.reduce((acc, player) => {
    acc[player.id] = player;
    return acc;
  }, {});

  const crosstable = sortedStandings.reduce((acc, standing, index) => {
    const row = {
      standing: index + 1,
      player: `${playerLookup[standing.player_id].voornaam} ${playerLookup[standing.player_id].achternaam}`,
      playerId: standing.player_id,
      scores: []
    };
    for (let i = 0; i < sortedStandings.length; i++) {
      const opponentStanding = sortedStandings[i];
      let score = round.matrix_score[standing.player_id][opponentStanding.player_id].toString().replace('0.5','½').replace('.5','½');

      if (standing.player_id === opponentStanding.player_id ) {
        score = 'XX';
      } else if (score == 0 && round.matrix_score[opponentStanding.player_id][standing.player_id] == 0)
      {
        score = "";
      }
      
      row.scores.push(score);
    }
    acc.push(row);
    return acc;
  }, []);


  return (
    <table className='col-sm-10 table table-striped crosstable'>
      <thead>
        <tr>
        <th></th><th></th>
          {sortedStandings.map((standing) => (
            <th key={standing.id}>
              {standing.Ranking}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {crosstable.map((row) => (
          <tr key={row.standing}>
            <td>{row.standing}</td>
            <td><NavLink to={`../speler/${row.playerId}`}>{row.player}</NavLink></td>
            {row.scores.map((score, index) => (
              <td key={index} className={score === "XX" ? 'black' : ''}>{score}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CompetitionCrosstable;
